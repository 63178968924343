/**
 * @generated SignedSource<<a846d9931a121530ab278fdf8045ea97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EdsJobIngestionFormat = "EDS_JOB_INGESTION_FORMAT_CSV" | "EDS_JOB_INGESTION_FORMAT_NDJSON" | "EDS_JOB_INGESTION_FORMAT_PSV" | "EDS_JOB_INGESTION_FORMAT_TSV" | "EDS_JOB_INGESTION_FORMAT_UNKNOWN" | "EDS_JOB_INGESTION_FORMAT_XML" | "%future added value";
export type EdsJobSync = "EDS_JOB_SYNC_CUSTOM_ATTRIBUTES" | "EDS_JOB_SYNC_CUSTOM_EVENTS" | "EDS_JOB_SYNC_ECOMMERCE_ADD_TO_CARTS" | "EDS_JOB_SYNC_ECOMMERCE_PRODUCT_VIEWS" | "EDS_JOB_SYNC_ECOMMERCE_PURCHASES" | "EDS_JOB_SYNC_MOBILE_WALLET" | "EDS_JOB_SYNC_PRODUCT_CATALOG" | "EDS_JOB_SYNC_SUBSCRIBER_OPT_INS" | "EDS_JOB_SYNC_SUBSCRIBER_OPT_OUTS" | "EDS_JOB_SYNC_TEXT_SEND" | "EDS_JOB_SYNC_TEXT_SEND_BULK" | "EDS_JOB_SYNC_UNKNOWN" | "EDS_JOB_SYNC_USER_IDENTIFIERS" | "%future added value";
export type EdsJobSyncMethod = "EDS_JOB_SYNC_METHOD_AMAZON_S3" | "EDS_JOB_SYNC_METHOD_ATTENTIVE_SFTP" | "EDS_JOB_SYNC_METHOD_CLIENT_HOSTED_SFTP" | "EDS_JOB_SYNC_METHOD_MANUAL_UPLOAD" | "EDS_JOB_SYNC_METHOD_S3" | "EDS_JOB_SYNC_METHOD_SFTP" | "EDS_JOB_SYNC_METHOD_SNOWFLAKE_DATA_SHARE" | "EDS_JOB_SYNC_METHOD_UNKNOWN" | "%future added value";
export type EncryptionType = "ENCRYPTION_TYPE_PGP" | "ENCRYPTION_TYPE_UNKNOWN" | "%future added value";
export type SyncStatus = "SYNC_STATUS_ACTIVE" | "SYNC_STATUS_INACTIVE" | "SYNC_STATUS_UNKNOWN" | "%future added value";
export type SyncDetail_EDSLib_Query$variables = {
  syncId: string;
};
export type SyncDetail_EDSLib_Query$data = {
  readonly sync: {
    readonly id?: string;
    readonly name?: string;
    readonly schedule?: {
      readonly " $fragmentSpreads": FragmentRefs<"label_getScheduleLabel_EDSLib_SyncSchedule">;
    };
    readonly status?: SyncStatus;
    readonly syncId?: string;
    readonly syncMethod?: EdsJobSyncMethod;
    readonly syncType?: EdsJobSync;
    readonly " $fragmentSpreads": FragmentRefs<"AttributeMapping_EDSLib_Sync" | "ConfigDetails_EDSLib_Sync">;
  } | null;
};
export type SyncDetail_EDSLib_Query$rawResponse = {
  readonly sync: {
    readonly __typename: "Sync";
    readonly connectionSettings: {
      readonly __typename: "AttentiveS3Settings";
      readonly bucket: string;
      readonly keyName: string;
      readonly timezone: string;
      readonly typename: "AttentiveS3Settings";
    } | {
      readonly __typename: "SftpSettings";
      readonly authKey: {
        readonly bucketName: string;
        readonly keyName: string;
      } | null;
      readonly filename: string;
      readonly host: string;
      readonly path: string;
      readonly port: number;
      readonly timezone: string;
      readonly typename: "SftpSettings";
      readonly username: string;
    } | {
      readonly __typename: string;
      readonly typename: string;
    } | null;
    readonly fetchOptions: {
      readonly matchPrefix: boolean;
      readonly maxFiles: number;
    };
    readonly fileSettings: {
      readonly encryption: {
        readonly privateKey: {
          readonly bucketName: string;
          readonly keyName: string;
        };
        readonly type: EncryptionType;
      } | null;
      readonly headerSettings: {
        readonly expectedColumnNum: number;
        readonly hasHeader: boolean;
        readonly rowStartNum: number;
      };
      readonly preprocess: boolean;
    };
    readonly fileType: EdsJobIngestionFormat;
    readonly id: string;
    readonly name: string;
    readonly schedule: {
      readonly schedule: {
        readonly __typename: "Cron";
        readonly cronString: string;
        readonly timezone: string;
        readonly type: "Cron";
      } | {
        readonly __typename: "OneTime";
        readonly runsImmediately: boolean;
        readonly type: "OneTime";
      } | {
        readonly __typename: string;
        readonly type: string;
      } | null;
    };
    readonly status: SyncStatus;
    readonly syncId: string;
    readonly syncMethod: EdsJobSyncMethod;
    readonly syncType: EdsJobSync;
    readonly targetConfig: any;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type SyncDetail_EDSLib_Query = {
  rawResponse: SyncDetail_EDSLib_Query$rawResponse;
  response: SyncDetail_EDSLib_Query$data;
  variables: SyncDetail_EDSLib_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "syncId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "syncId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "syncId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "syncType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "syncMethod",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cronString",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "runsImmediately",
      "storageKey": null
    }
  ],
  "type": "OneTime",
  "abstractKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "keyName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v13 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bucketName",
    "storageKey": null
  },
  (v11/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SyncDetail_EDSLib_Query",
    "selections": [
      {
        "alias": "sync",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AttributeMapping_EDSLib_Sync"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ConfigDetails_EDSLib_Sync"
              },
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SyncSchedule",
                "kind": "LinkedField",
                "name": "schedule",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "label_getScheduleLabel_EDSLib_SyncSchedule",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "schedule",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v9/*: any*/)
                            ],
                            "type": "Cron",
                            "abstractKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Sync",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SyncDetail_EDSLib_Query",
    "selections": [
      {
        "alias": "sync",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "targetConfig",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "connectionSettings",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": "typename",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bucket",
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "type": "AttentiveS3Settings",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SftpAuthKey",
                        "kind": "LinkedField",
                        "name": "authKey",
                        "plural": false,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "filename",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "host",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "path",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "port",
                        "storageKey": null
                      },
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      }
                    ],
                    "type": "SftpSettings",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FetchOptions",
                "kind": "LinkedField",
                "name": "fetchOptions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "matchPrefix",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maxFiles",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fileType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FileSettings",
                "kind": "LinkedField",
                "name": "fileSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Encryption",
                    "kind": "LinkedField",
                    "name": "encryption",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PrivateKey",
                        "kind": "LinkedField",
                        "name": "privateKey",
                        "plural": false,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HeaderSettings",
                    "kind": "LinkedField",
                    "name": "headerSettings",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expectedColumnNum",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasHeader",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rowStartNum",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "preprocess",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SyncSchedule",
                "kind": "LinkedField",
                "name": "schedule",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "schedule",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v9/*: any*/),
                          (v12/*: any*/)
                        ],
                        "type": "Cron",
                        "abstractKey": null
                      },
                      (v10/*: any*/),
                      {
                        "alias": "type",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/)
            ],
            "type": "Sync",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f0558c3248868a3dcd117b3116f2fa78",
    "id": null,
    "metadata": {},
    "name": "SyncDetail_EDSLib_Query",
    "operationKind": "query",
    "text": "query SyncDetail_EDSLib_Query(\n  $syncId: ID!\n) {\n  sync: node(id: $syncId) {\n    __typename\n    ... on Sync {\n      ...AttributeMapping_EDSLib_Sync\n      ...ConfigDetails_EDSLib_Sync\n      id\n      name\n      syncId\n      syncType\n      syncMethod\n      status\n      schedule {\n        ...label_getScheduleLabel_EDSLib_SyncSchedule\n      }\n    }\n    id\n  }\n}\n\nfragment AttributeMappingTable_EDSLib_Sync on Sync {\n  targetConfig\n}\n\nfragment AttributeMapping_EDSLib_Sync on Sync {\n  ...AttributeMappingTable_EDSLib_Sync\n}\n\nfragment ConfigDetails_EDSLib_Sync on Sync {\n  ...ConnectionSettings_EDSLib_Sync\n  ...FileSettings_EDSLib_Sync\n  ...ScheduleSettings_EDSLib_Sync\n}\n\nfragment ConnectionSettings_EDSLib_Sync on Sync {\n  connectionSettings {\n    __typename\n    typename: __typename\n    ... on AttentiveS3Settings {\n      bucket\n      keyName\n      timezone\n    }\n    ... on SftpSettings {\n      authKey {\n        bucketName\n        keyName\n      }\n      filename\n      host\n      path\n      port\n      timezone\n      username\n    }\n  }\n  fetchOptions {\n    matchPrefix\n    maxFiles\n  }\n  syncMethod\n}\n\nfragment FileSettings_EDSLib_Sync on Sync {\n  fileType\n  fileSettings {\n    encryption {\n      type\n      privateKey {\n        bucketName\n        keyName\n      }\n    }\n    headerSettings {\n      expectedColumnNum\n      hasHeader\n      rowStartNum\n    }\n    preprocess\n  }\n}\n\nfragment ScheduleSettings_EDSLib_Sync on Sync {\n  schedule {\n    ...label_getScheduleLabel_EDSLib_SyncSchedule\n    schedule {\n      __typename\n      type: __typename\n      ... on Cron {\n        cronString\n        timezone\n      }\n      ... on OneTime {\n        runsImmediately\n      }\n    }\n  }\n}\n\nfragment label_getScheduleLabel_EDSLib_SyncSchedule on SyncSchedule {\n  schedule {\n    __typename\n    ... on Cron {\n      cronString\n    }\n    ... on OneTime {\n      runsImmediately\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2e983c7915947d299e34cc05deada9f0";

export default node;
